import querystring from 'query-string';
import { getATLContextUrl } from '@atlassian/atl-context';
import getLocation from '@atlassian/jira-common-location';
import type {
	BoardItem,
	DashboardItem,
	ProjectItem,
	FilterItem,
	QueueItem,
	PlanItem,
	AddonItem,
} from '@atlassian/jira-nav-item-service/src/types';
import { browseProjectRedirectRoute } from '@atlassian/jira-router-routes-browse-project-routes';
import { jiraBusinessProjectRedirectRoute } from '@atlassian/jira-router-routes-business-redirect-routes';
import { jsmBoardRoute } from '@atlassian/jira-router-routes-servicedesk-board-routes';
import { projectServiceDeskRoute } from '@atlassian/jira-router-routes-servicedesk-default-routes';
import {
	customPracticeQueueRoute,
	customQueueRoute,
} from '@atlassian/jira-router-routes-servicedesk-queue-routes';
import { softwareBoardRoute } from '@atlassian/jira-router-routes-software-board-routes';
import { classicSoftwareBoardRoute } from '@atlassian/jira-router-routes-software-classic-board-routes';
import { userBoardRoute } from '@atlassian/jira-router-routes-user-board-routes';
import { toShortFormItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/controllers/to-work-category-short-form/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { ADDON_ID } from '../constants';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { testIdGenerate, testIdConcat } from './test-id';

export const useAtlassianLoginLink = () => `${getATLContextUrl('id')}/login`;

export const useAtlassianPersonalSettingsLink = (destinationAccountEmail?: string) => {
	const loginLink = useAtlassianLoginLink();
	const destinationUrl = `${getATLContextUrl('id')}/manage-profile/profile-and-visibility`;

	return destinationAccountEmail
		? `${loginLink}?${querystring.stringify({
				application: 'jira',
				continue: destinationUrl,
				login_hint: destinationAccountEmail,
				prompt: 'none',
			})}`
		: destinationUrl;
};

export const useSwitchAccountLink = () => {
	const loginLink = useAtlassianLoginLink();
	const { href } = getLocation() || {};

	return `${loginLink}?${querystring.stringify({
		application: 'jira',
		continue: href,
		prompt: 'select_account',
	})}`;
};

export type UseAtlassianPersonalSettingsLink = () => string;
export type UseAtlassianLoginLink = () => string;

export const useProfileLink = () => {
	const accountId = useAccountId();

	return `/jira/people/${accountId || ''}`;
};

export type UseProfileLink = () => string;

type GetBeforeEntityFull = {
	id: string;
	type:
		| BoardItem['type']
		| DashboardItem['type']
		| ProjectItem['type']
		| FilterItem['type']
		| QueueItem['type']
		| PlanItem['type'];
};
type GetBeforeEntityEmpty = {
	id: undefined;
	type: undefined;
};

export const getBeforeEntity = (
	favoriteItems:
		| BoardItem[]
		| DashboardItem[]
		| ProjectItem[]
		| FilterItem[]
		| QueueItem[]
		| PlanItem[],
): GetBeforeEntityFull | GetBeforeEntityEmpty =>
	favoriteItems && favoriteItems.length > 0
		? // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			({
				id: String(favoriteItems[0].id),
				type: favoriteItems[0].type,
			} as GetBeforeEntityFull)
		: // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			({ id: undefined, type: undefined } as GetBeforeEntityEmpty);

export const getBoardLink = (item: BoardItem) => {
	const {
		attributes,
		attributes: { parentType },
		id,
	} = item;

	const boardId = String(id);

	if (parentType === 'project') {
		// @ts-expect-error - TS2339 - Property 'nextgen' does not exist on type 'ProjectBoardItemAttributes | UserBoardItemAttributes'. | TS2339 - Property 'projectKey' does not exist on type 'ProjectBoardItemAttributes | UserBoardItemAttributes'. | TS2339 - Property 'projectType' does not exist on type 'ProjectBoardItemAttributes | UserBoardItemAttributes'.
		const { nextgen, projectKey, projectType } = attributes;

		if (projectType === 'business') {
			return {
				params: {
					projectKey,
				},
				query: {},
				route: jiraBusinessProjectRedirectRoute,
			};
		}

		if (projectType === 'service_desk') {
			return {
				params: {
					boardId,
					projectKey,
				},
				query: {},
				route: jsmBoardRoute,
			};
		}

		if (nextgen === 'true') {
			return {
				params: {
					boardId,
					projectKey,
				},
				query: {},
				route: softwareBoardRoute,
			};
		}

		return {
			params: {
				boardId,
				projectKey,
			},
			query: {},
			route: classicSoftwareBoardRoute,
		};
	}

	if (parentType === 'user') {
		// @ts-expect-error - TS2339 - Property 'userAccountId' does not exist on type 'ProjectBoardItemAttributes | UserBoardItemAttributes'.
		const { userAccountId: accountId } = attributes;
		return {
			params: {
				boardId,
				accountId,
			},
			query: {},
			route: userBoardRoute,
		};
	}

	return {
		params: undefined,
		query: undefined,
		route: undefined,
	};
};

export const getLinkFromProject = (item: ProjectItem) => {
	const {
		attributes: { projectType, boardId, nextgen, projectKey },
	} = item;

	if (projectType === 'business') {
		return {
			params: {
				projectKey,
			},
			query: {},
			route: jiraBusinessProjectRedirectRoute,
		};
	}

	if (boardId !== undefined) {
		if (nextgen === 'true') {
			return {
				params: {
					boardId,
					projectKey,
				},
				query: {},
				route: softwareBoardRoute,
			};
		}

		return {
			params: {
				boardId,
				projectKey,
			},
			query: {},
			route: classicSoftwareBoardRoute,
		};
	}

	if (projectType === 'service_desk') {
		return {
			params: {
				projectKey,
			},
			query: {},
			route: projectServiceDeskRoute,
		};
	}

	return {
		params: { projectKey },
		query: {},
		route: browseProjectRedirectRoute,
	};
};

export const getQueueLink = (item: QueueItem) => {
	const {
		attributes: { projectKey, queuesCategory },
		id,
	} = item;

	return {
		params: {
			projectKey,
			...(queuesCategory != null && queuesCategory !== ''
				? { practiceType: toShortFormItsmPractice(queuesCategory) }
				: {}),
			queueId: String(id),
		},
		route:
			queuesCategory != null && queuesCategory !== '' ? customPracticeQueueRoute : customQueueRoute,
	};
};

export const getIsClassicSoftwareProject = (attributes: BoardItem['attributes']) =>
	attributes.parentType === 'user' ||
	(attributes.parentType === 'project' &&
		attributes.projectType === 'software' &&
		attributes.nextgen === 'false');

export const getUsersApps = (items: AddonItem[]) =>
	items.filter((item) => item.id !== ADDON_ID.DISCOVER);
